import { Heading, Box, Page, WixDesignSystemProvider, Button, Text, Loader } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import './App.css';
import * as Icons from '@wix/wix-ui-icons-common';
import { ReactTagManager } from 'react-gtm-ts';
import React from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import Download from 'react-csv/components/Download';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  const [data, setData] = React.useState([]);
  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=2d20bfc3-a14d-48c2-9384-a6ab926e9cfd&redirectUrl=https://certifiedcode.editorx.io/wizard/_functions/@certifiedcode/base-backend/auth`;
  }

  // check if url includes exportId, if so, get the export data (array) from https://certifiedcode.wixsite.com/wizard/_functions/export/exportId and download as csv
  const exportId = new URLSearchParams(window.location.search).get('exportId');
  if (exportId && data.length === 0) {
    fetch(`https://certifiedcode.wixsite.com/wizard/_functions/export/${exportId}`, {
      method: "GET"
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data)
      })
  }
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Header
          title={<Box gap={"SP2"} alignItems="middle">
            {data.length === 0 ? <Heading>One sec...</Heading> : <Heading>Ready!</Heading>}
          </Box>}
        // actionsBar={
        //   <Box gap={"SP2"} direction="horizontal">
        //     {isPremiumApp ? <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />} onClick={() => setIsUpgradeModalOpen(true)}>Unlock All Features</Button>
        //       : <></>}
        //   </Box>
        // }
        />
        <Page.Content>
          <CSVLink
            data={data}
            filename={`export-${exportId}.csv`}
            target="_blank"
          ><Button
            disabled={data.length === 0}
            suffixIcon={data.length > 0 ? <Icons.Download /> : undefined}>{data.length > 0 ? "Download CSV" : <Loader size="tiny" />}
            </Button>
          </CSVLink>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
